<template>
  <div class="container">
    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else>
      <div class="my-4">
        <h4 class="text-center">Список заявок на вакансии</h4>
      </div>

      <div class="my-3">
        <DataTable :value="commissionDecision_form.vacancyApplications" showGridlines stripedRows
                   :globalFilterFields="['lastname','firstname']"
                   v-model:filters="filters"
                   :paginator="true"
                   :rows="20"
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[5,10,20,30,40,50]"
                   responsiveLayout="scroll"
                   currentPageReportTemplate="Показано с {first} по {last} из {totalRecords}">
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск кандидата"/>
              </span>
            </div>
          </template>
          <template #empty>
            Кандидат не найден
          </template>
          <template #loading>
            Идет поиск кандидатов. Пожалуйста подождите.
          </template>

          <Column header="№" :style="{width: '80px'}">
            <template #body="{index}">
              {{index+1}}
            </template>
          </Column>

          <Column header="ФИО">
            <template #body="{data}">

              <a @click.prevent="goToUser(data.user)" class="text-decoration-none" href="#">
                {{data.lastname}} {{data.firstname}}
              </a>

            </template>
          </Column>

          <Column header="Дисциплины">
            <template #body="{data}">
              <div v-for="(application, index) in data.applications"
                   :key="index">
                <div class="fw-bold">
                  <a @click.prevent="openDisciplineEducationProgramDialog(application.discipline_id)" class="text-decoration-none" href="#">
                    {{application.discipline_name}}
                  </a>

                </div>
                <div>{{application.study_level_name}} - {{application.language}}</div>

                <div v-if="application.commission_decision_id == null">
                  <input class="form-check-input" type="checkbox" @change="changeApplication(application.user_syllabus_id, data.user, application.discipline_name, $event)">
                </div>
                <div v-else>
                  <span v-if="application.commission_status == 1">
                    <i class="pi pi-check" style="font-size: 0.9rem; color: green; font-weight: bold"></i>
                  </span>
                  <span v-else>
                    <i class="pi pi-times" style="font-size: 0.9rem; color: red; font-weight: bold"></i>
                  </span>
                </div>

                <br>
              </div>
            </template>
          </Column>


          <Column header="Действия" class="text-center" :style="{width: '15%'}">
            <template #body="{data}">
              <div v-if="data.applications.length != applicationsStatusCount(data.user)">
                <Button class="p-button-rounded p-button-success me-1" icon="pi pi-check" @click="openConfirmDialog(data.user)"/>
              </div>
              <div v-else>
                <p>Выбор сделан</p>
              </div>

<!--              <Button class="p-button-rounded p-button-danger" icon="pi pi-times" @click="openRejectDialog(data.user)"/>-->
            </template>
          </Column>
        </DataTable>


      </div>


    </div>
<!--    <ConfirmDialog></ConfirmDialog>-->

<!--    <Dialog header="Отказ" v-model:visible="rejectDisplay" :breakpoints="{'960px': '75vw'}" :style="{width: '50vw'}">-->

<!--      <div class="col-md-12">-->
<!--        <div class="mt-4">-->

<!--          <ul>-->
<!--            <p>Выбранные дисциплины:</p>-->
<!--            <li v-for="(item, index) in checkedApplications.filter(i=>i.user_id == this.reject_user)"-->
<!--                :key="index">-->
<!--              {{item.discipline_name}}-->
<!--              <textarea class="form-control mb-2" rows="5"-->
<!--                        @change="changeRejectComment(item.user_syllabus_id, $event)"-->
<!--                        placeholder="Причина отказа"></textarea>-->
<!--            </li>-->

<!--          </ul>-->
<!--        </div>-->
<!--      </div>-->

<!--      <template #footer>-->
<!--        <Button label="Закрыть" icon="pi pi-times" @click="closeRejectDialog" class="p-button-text"/>-->
<!--        <Button label="Отказать" icon="pi pi-check" @click="submitRejectDecision" autofocus />-->
<!--      </template>-->
<!--    </Dialog>-->



    <Dialog header="Решение по дисциплинам" v-model:visible="confirmDisplay" :breakpoints="{'960px': '75vw'}" :style="{width: '50vw'}" :closable="false">

      <div class="col-md-12">
        <div class="mt-4" v-if="decisionDisciplines.filter(i=>i.commission_status == 1).length > 0">
          <p>Cписок дисциплин для принятия:</p>
          <ul>

            <li v-for="(item, index) in decisionDisciplines.filter(i=>i.commission_status == 1)"
                :key="index">
              {{item.discipline_name}}

            </li>

          </ul>
        </div>
        <div v-else>
          <p>Cписок дисциплин для принятия:</p>
          <ul>
            <li>Дисциплины не выбраны</li>

          </ul>
        </div>

        <div class="mt-4" v-if="decisionDisciplines.filter(i=>i.commission_status == null).length > 0">
          <p>Cписок дисциплин для отказа:</p>
          <ul>

            <li v-for="(item, index) in decisionDisciplines.filter(i=>i.commission_status == null)"
                :key="index">
              {{item.discipline_name}}
              <textarea class="form-control mb-2" rows="5"
                        @change="changeRejectComment(item.user_syllabus_id, $event)"
                        placeholder="Причина отказа">
              </textarea>
            </li>

          </ul>
        </div>

      </div>

      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeConfirmDialog" class="p-button-text"/>
        <Button label="Сохранить" icon="pi pi-check" @click="submitDecision" autofocus />
      </template>
    </Dialog>

    <Dialog header="ОП Дисциплины" v-model:visible="disciplineEducationProgramDisplay" :breakpoints="{'960px': '75vw'}" :style="{width: '50vw'}">

      <div class="col-md-12">
        <div class="mt-4">

          <ul>
            <p>Список ОП в которых прикреплена дисциплина:</p>
            <li v-for="(item, index) in commissionDecision_form.educationProgramsByDiscipline" :key="index">
              <strong>{{item.education_speciality_code}} {{item.education_speciality_name}} -</strong>  {{item.language}} - {{item.study_level_name}}
            </li>
          </ul>
        </div>
      </div>


      <template #footer>
        <Button label="Закрыть" icon="pi pi-times" @click="closeDisciplineEducationProgramDialog" class="p-button-text"/>
      </template>
    </Dialog>

  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'
import {FilterMatchMode, FilterOperator} from 'primevue/api';

export default {
  name: 'CommissionDecision',
  data() {
    return {
      loading: true,
      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},

        'lastname': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'firstname': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      },
      // checkedApplications: [],
      // rejectDisplay: false,
      // reject_user: null,

      disciplineEducationProgramDisplay: false,

      confirmDisplay: false,
      confirm_user: null,
      decisionDisciplines: []

    }
  },
  computed: {
    ...mapState('commissionDecision', ['commissionDecision_form']),

  },
  methods: {
    ...mapActions('commissionDecision', ['GET_VACANCY_APPLICATIONS', 'POST_COMMISSION_DECISION', 'GET_EDUCATION_PROGRAMS_BY_DISCIPLINE']),
    ...mapMutations('userinfo', ['SET_USER_ID']),
    applicationsStatusCount(user_id) {
      let applications = this.commissionDecision_form.vacancyApplications.find(i=>i.user === user_id).applications
      return applications.reduce((sum, i) => (i.commission_decision_id != null ? 1 : 0) + sum, 0)
    },
    openDisciplineEducationProgramDialog(discipline_id) {
      this.disciplineEducationProgramDisplay = true
      this.GET_EDUCATION_PROGRAMS_BY_DISCIPLINE(discipline_id)
    },
    closeDisciplineEducationProgramDialog() {
      this.disciplineEducationProgramDisplay = false
    },
    goToUser(userId) {
      this.SET_USER_ID(userId)
      this.$router.push('/user')
    },
    // openRejectDialog(user_id){
    //   this.rejectDisplay = true
    //   this.reject_user = user_id
    // },
    // closeRejectDialog(user){
    //   this.rejectDisplay = false
    // },
    openConfirmDialog(user_id){
      this.confirmDisplay = true
      this.confirm_user = user_id
      console.log(user_id, 'user_id', this.confirm_user, 'this.confirm_user')
      this.decisionDisciplines = this.commissionDecision_form.vacancyApplications.find(i=>i.user === user_id).applications
      console.log(this.decisionDisciplines, 'decisionDisciplines')
    },
    closeConfirmDialog(){
      this.confirmDisplay = false
    },

    async submitDecision() {
      this.confirmDisplay = false

      let form = this.decisionDisciplines.map(i => ({
        user_syllabus_id: i.user_syllabus_id,
        commission_status: i.commission_status == 1 ? 1 : 0,
        commission_comment: i.commission_comment ? i.commission_comment : null,

      }))
      console.log(form, 'submit form')

      let postData = await this.POST_COMMISSION_DECISION(form)
      if (postData) {

        await this.GET_VACANCY_APPLICATIONS()
        this.$message({text: 'Заявки приняты'})
        //this.$toast.add({severity:'success', summary:'Принято', detail:'Вы приняли', life: 3000});
      }
      this.decisionDisciplines = []
      this.confirm_user = null
    },

    // async submitRejectDecision() {
    //   this.rejectDisplay = false
    //   let app = this.checkedApplications.filter(i=>i.user_id == this.reject_user)
    //   let form = app.map(i => ({
    //     user_syllabus_id: i.user_syllabus_id,
    //     commission_status: 0,
    //     commission_comment: i.commission_comment,
    //
    //   }))
    //   console.log(form, 'submit reject form')
    //
    //   let postData = await this.POST_COMMISSION_DECISION(form)
    //   if (postData) {
    //
    //     await this.GET_VACANCY_APPLICATIONS()
    //     this.$message({text: 'Заявки на вакансию отклонены'})
    //     //this.$toast.add({severity:'success', summary:'Принято', detail:'Вы приняли', life: 3000});
    //   }
    //   this.checkedApplications = []
    //   this.reject_user = null
    // },
    changeRejectComment(user_syllabus_id, e) {
      const value = e.target.value
      console.log(user_syllabus_id, 'changeRejectComment user_syllabus_id')

      this.decisionDisciplines.find(i=>i.user_syllabus_id === user_syllabus_id)['commission_comment'] = value


      console.log(this.decisionDisciplines, 'comment decisionDisciplines')

    },

    // changeApplication(user_syllabus_id, user, discipline_name, e) {
    //   console.log(user_syllabus_id, 'user_syllabus_id')
    //   console.log(user, 'user')
    //   console.log(discipline_name, 'discipline_name')
    //   if(this.checkedApplications.find(i=>i.user_syllabus_id == user_syllabus_id)) {
    //     //let index = this.checkedApplications.indexOf(this.checkedApplications.find(i=>i.user_syllabus_id == user_syllabus_id))
    //     let index = this.checkedApplications.findIndex(i=>i.user_syllabus_id == user_syllabus_id)
    //     this.checkedApplications.splice(index, 1);
    //   }
    //   else {
    //     this.checkedApplications.push({
    //       user_syllabus_id: user_syllabus_id,
    //       user_id: user,
    //       discipline_name: discipline_name,
    //       commission_comment: null
    //     })
    //   }
    //
    //   console.log(this.checkedApplications, 'checkedApplications')
    // },
    changeApplication(user_syllabus_id, user, discipline_name, e) {
      console.log(user_syllabus_id, 'user_syllabus_id')
      console.log(user, 'user')
      console.log(discipline_name, 'discipline_name')

      console.log(this.commissionDecision_form.vacancyApplications.find(i=>i.user === user).applications.find(i=>i.user_syllabus_id === user_syllabus_id)['commission_status'], 'SDGSDGSDGSDG')
      if(this.commissionDecision_form.vacancyApplications.find(i=>i.user === user).applications.find(i=>i.user_syllabus_id === user_syllabus_id)['commission_status'] == null) {
        this.commissionDecision_form.vacancyApplications.find(i=>i.user === user).applications.find(i=>i.user_syllabus_id === user_syllabus_id)['commission_status'] = 1
      }
      else if (this.commissionDecision_form.vacancyApplications.find(i=>i.user === user).applications.find(i=>i.user_syllabus_id === user_syllabus_id)['commission_status'] == 1) {
        this.commissionDecision_form.vacancyApplications.find(i=>i.user === user).applications.find(i=>i.user_syllabus_id === user_syllabus_id)['commission_status'] = null
      }
      console.log(this.commissionDecision_form.vacancyApplications, 'vacancyApplications')
    },

    // confirm(user) {
    //   this.$confirm.require({
    //     message: 'Вы уверены что хотите принять?',
    //     header: 'Принятие',
    //     icon: 'pi pi-exclamation-triangle',
    //     accept: async () => {
    //       let app = this.checkedApplications.filter(i=>i.user_id == user)
    //       let form = app.map(i => ({
    //         user_syllabus_id: i.user_syllabus_id,
    //         commission_status: 1,
    //
    //       }))
    //       console.log(form, 'submit confirm form')
    //
    //
    //
    //       let postData = await this.POST_COMMISSION_DECISION(form)
    //       if (postData) {
    //
    //         await this.GET_VACANCY_APPLICATIONS()
    //         this.$message({text: 'Заявки на вакансию приняты'})
    //         //this.$toast.add({severity:'success', summary:'Принято', detail:'Вы приняли', life: 3000});
    //       }
    //       this.checkedApplications = []
    //       this.reject_user = null
    //
    //
    //     },
    //
    //   });
    // },

  },
  async mounted() {
    await this.GET_VACANCY_APPLICATIONS()
    this.loading = false
  },
}

</script>